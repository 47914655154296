export const Breakpoints = {
  desktop: 1280,
  tablet: 991,
  mobile: 640,
};

type BreakpointKey = keyof typeof Breakpoints | number;

type BreakpointFunction = (point: BreakpointKey) => string;

export const breakpoint: BreakpointFunction = (point) =>
  `@media only screen and (max-width: ${
    typeof point === `number` ? point : Breakpoints[point]
  }px)`;

export const breakpointReverse: BreakpointFunction = (point) =>
  `@media only screen and (min-width: ${
    typeof point === `number` ? point : Breakpoints[point]
  }px)`;

import { useEffect, useState } from 'react';

export const isBrowser = typeof window !== `undefined`;

// user-agent통해 모바일 기기인지 판단
export const isMobileDevice = (): boolean =>
  isBrowser &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const openPopup: typeof window.open = (url, target, features) => {
  if (!isBrowser) return null;
  const popup = window.open(url, target, features);
  if (!popup) {
    // eslint-disable-next-line no-alert
    alert(
      `팝업 차단 기능이 설정되어있습니다\n\n차단 기능을 해제(팝업허용) 한 후 다시 이용해 주십시오.`,
    );
  }
  // ios용 설정
  popup?.focus();
  return popup;
};

// ie에만 호환성 코드가 필요한 경우를 위한 ie 감지 헬퍼
export const isIE = (): boolean =>
  window.navigator.userAgent.indexOf(`MSIE `) > 0 ||
  !!navigator.userAgent.match(/Trident.*rv:11\./);

export const useWindowSizeCustom = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          // 현재 브라우저의 가로, 세로 길이로 셋팅
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      handleResize();

      // 이벤트 리스너 제거
      return () => window.removeEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', () => null);
  }, []);
  return windowSize;
};
